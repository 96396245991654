import React from 'react'
import { Container1 } from './BrandingsElements'
function LargerFormat(data) {
    return (
        <Container1>
            <div><ul>
                <li>
                    Roll-up Banners
                </li>
                <li>
                    Stikers / Posters
                </li>
                <li>
                    Magnetic foil
                </li>
                <li>
                    Reflective tape
                </li>
            </ul></div>
            <div><ul>
                <li>
                    Flags
                </li>
                <li>
                    A- Boards
                </li>
                <li>
                    Canvas
                </li>
                <li>
                    Blueback
                </li>
            </ul>
            </div>
            <div><ul>
                <li>
                    Interior & Outdoor Signage
                </li>
                <li>
                    X-Banners
                </li>
                <li>Wallpapers
                </li>
                <li>Back Drops
                </li>
            </ul></div>
        </Container1>
    )
}

export default LargerFormat