import styled from "styled-components"

export const ButtonContainer = styled.button`
    background: #00d2d1;
    color: #073c64;
    font-weight: 600;
    border: none;
    border-radius: 20px;
    font-size: 20px;
    padding: 10px 25px;
    padding-top: 14px;
    cursor: pointer;
box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
 
   
    /* width: 12%; */

    &:hover{
        transition: .3s ease-in-out;
        color: #fff;
    }

    @media screen and (max-width: 960px){
        font-size: 18px;
        padding: 10px 25px;
        margin-left: auto;
        margin-right: auto;
    }
`