import React from 'react'
import { Container, ContentSide, ImageSection, ImageSide, Paragraph, Title, Wrapper } from './BrandingsElements'
import Button from '../Button'
import { Link } from 'react-router-dom'

const Digital = ({ data }) => {



    return (
        <Container id="brands">
            <Wrapper ImgStart={data.ImgStart} >
                <ImageSide>
                    <ImageSection>
                        <img src={data.img} alt="" />
                    </ImageSection>
                </ImageSide>

                <ContentSide>
                    <Title>
                        {data.title}
                    </Title>

                    <Paragraph>
                        {data.description}
                    </Paragraph>

                    <Link to="/contact"><Button title="Get Started" /></Link>

                </ContentSide>
            </Wrapper>

        </Container>
    )
}

export default Digital
