import styled from "styled-components";
import { Link } from "react-router-dom";


export const FooterContainer = styled.footer`

    background: #fff;
    height: 25vh;
    padding: 2.5% 15%;
`

export const FooterWrapper = styled.div`
    /* padding: 2.5% 15%; */
    text-align: center;

    @media screen and (max-width: 960px){
        margin: 5% 2.5%;
    }
    
    

`

export const FooterTop = styled.h2`
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 1px;


        
    

    @media screen and (max-width: 960px){
        font-size: 16px;
    }

`

export const NavLinks = styled(Link)`
    text-decoration: none;
    color: #000;

    &:hover{
        color: #00D3E3;
        
    }
`


export const FooterSocial = styled.div`
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 20px;

    @media screen and (max-width: 960px){
        width: 60%;
        margin-top: 20px;
        
    }


`

export const SocialIcons = styled(Link)`
    font-size: 30px;
    cursor: pointer;
    color: #000;

    &:hover{
        color: #00D3E3;
        transform: scale(1.2) rotate(-7deg);
        
        
    }

    @media screen and (max-width: 960px){
        font-size: 24px;
    }
`

export const FooterBottom = styled.h3`
    font-weight: lighter;

    @media screen and (max-width: 960px){
        font-size: 16px;
    }

    a{
        text-decoration: none;
        color: #000;
    }
    a:hover{
        color: #00D3E3;
    }
`