import businessCardImage from "../../images/bussinesscards.png";
import PostersImage from "../../images/folder.png";
import flyersImages from "../../images/flyers-removebg-preview.png";
import Banner from "../../images/banners-removebg-preview.png";
import vehicleBrandingImage from "../../images/vehiclebranding-removebg-preview.png";
import wallBrandingImage from "../../images/wallbranding.jpg";
import TextilesImage from "../../images/hoodies-removebg-preview.png";
import LightersImage from "../../images/flashb-removebg-preview.png";
import MugsImage from "../../images/mugs-removebg-preview.png"

export const BusinessCards = {
  title: "Business cards",
  description:
    `we offer a business card in a
very good quality, single or double sided. Our graphic design studio
will develop a design that allows your company to stand out from the
crowd. We offer different types of refining business cards i.e. foils, varnishes, selective varnishes UV, etc.`,
  ImgStart: true,
  img: businessCardImage,
};
export const Posters = {
  title: "Folders",
  description:
    `we offer a folder
with increased volume and thickness of the back.For increased durability and aesthetics, we can use a number of finishes in the form of surface coating with a dispersion varnish, we can use the foliation (mate
or gloss) or lay selective UV varnish, which will expose selected graphic elements.`,
  ImgStart: false,
  img: PostersImage,
};
export const Flyers = {
  title: "Flyers",
  description:
    `we offer a wide range of
leaflets on a variety of formats and our vehicleBranding.pngown graphic design studio
makes our leaflets arouse interest to the public. We print our issues
from the A7 to A4 size.`,
  ImgStart: true,
  img: flyersImages,
};
export const VehicleBranding = {
  title: "Vehicle branding",
  description:
    `we offer our clients comprehensive services in this area.
The car may be covered entirely using high-end cast foils, and to the
rear and side windows can have the OWV foil (One Way Vision). The
foils are printed on the most modern plotters allowing to stand out with
print quality. We secure the print with laminate`,
  ImgStart: false,
  img: vehicleBrandingImage,
};
export const Banners = {
  title: "Banners",
  description:
    `we offer you can fnd a wide range of visual
advertising. We make banners single- and double-sided printed on
high-quality materials characterized by full colour and sharpness of
colours.`,
  ImgStart: true,
  img: Banner,
};

export const WallBranding = {
  title: "Wall branding",
  description:
    `This is a very cost effective medium of brand visibility used to increase
awareness and recognition as well as build our clients’ brands in both
the urban and remotest areas of the country where other branding
methods are not available. In so doing, we do not only contribute to
your brand’sequity but also add value and power to your brands.`,
  ImgStart: true,
  img: wallBrandingImage,
};


export const Textiles = {
  title: "Textiles",
  description:
    `Depending on the load of material and using different techniques we
print on T-shirts, shirts, sweatshirts, hats, and other fabrics. We have a
full range of clothing sizes and we also print on materials entrusted by
the Client. We carry out orders from 1 piece.`,
  ImgStart: true,
  img: TextilesImage,
};

export const Lighters = {
  title: "Flash drive",
  description:
    `Imprints on USB storage devices complete our offer. Direct printing in
full colour takes full advantage of the print surfaces. A personalized
USB memory is a great marketing product with a wide range of applications.`,
  ImgStart: false,
  img: LightersImage,
};

export const Mugs = {
  title: "Mugs",
  description:
    `Mugs, thanks to its usefulness and widespread use, are an ideal
medium for advertising. As a company gift or gadget it works on any
occasion. Mugs with your photo, a dedication or a sentence can hit the
desk of all the employees in the company, they can be a memorial after
a successful meeting or impress with their originality. We produce
small series and single copies, also on the material entrusted by the
client.`,
  ImgStart: true,
  img: MugsImage,
};

export const otherPrinting = ["Letter head ", "Folder", "Desk Pad Planners", "Packaging", "Packages", "Stickers", "Catalogues", "Christmas and other cards", "Calendars", "Labels", "Envelopes"];

export const LargeFormat = ["Roll-up Banners", "Stickers / Posters", "Reflective tape", "Flags", "A- Boards", "Canvas", "Blueback", "Interior & Outdoor Signage", "X-Banners", "Wallpapers", "Back Drops"];

export const OtherGadgets = ["lanyards", "Magnetic foil", "Pen", "Reflective bands", "Flash drive", "Product information", "Gondolas", "Hanging Signs", "Light Boxes"];