import React from 'react'
import Button from '../Button'
import { Link } from 'react-router-dom'
import { ContainerTitle } from './BrandingsElements'

function GetStartedButton() {
  return (
      <ContainerTitle> <Link to="/contact"><Button title="Get Started" /></Link></ContainerTitle>
  )
}

export default GetStartedButton