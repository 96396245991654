import styled from "styled-components";
import { FaMagento } from "react-icons/fa";
import { NavLink } from 'react-router-dom';

export const Nav = styled.div`
    background: #FFF;
    height: 80px;
    display: flex;
    
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;

    @media screen and (max-width: 960px){
        height: 60px;
        justify-content: center;
    align-items: center;
    }
    `;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    margin-right: 15%;
    margin-left: 15%;
    
  

  @media screen and (max-width: 991px){
    margin-left: 2.5%;
  }
    `

export const NavLogo = styled(NavLink)`
    color: #000;
    justify-self: flex-start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center; 
    
    img{
        height: 70px;
        padding: 5px 0;

        @media screen and (max-width: 960px)
        {
            height: 60px;
        }
    } 
    `

export const NavIcon = styled(FaMagento)`
    margin-right: 0.5rem;
    `

export const MobileIcon = styled.div`
    display: none;
    
    @media screen and (max-width: 960px){
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;

    }`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    
    
    @media screen and (max-width: 960px){
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 60px;
        left: ${({ click }) => (click ? 0 : '-100%')};
        opacity: 1;
        transition: all 0.5s ease;
        background: #FFF;

    }`;

export const NavItem = styled.li`
    height: 80px;
    border-bottom: 2px solid transparent;
    
    &:hover{
        border-bottom: 5px solid #fff;  
    }
    
    @media screen and (max-width: 960px){
        width: 100%;

        &:hover{
            border: none;
        }
    }`

export const NavLinks = styled(NavLink)`
    color: #000;
    /* font-weight: bold; */
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-size: 13px;

    &:active{
        /* color :red; */
    }

   
    
    @media screen and (max-width: 960px){
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;

        &:hover {
            color: #4b59f7;
            transition: all 0.3s ease;
        }
    }`