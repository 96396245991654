import React from 'react'
import HomePage from '../HomePage'
import Products from '../Products'
import { BodyContainer } from './BodyElements'
import { useLocation } from 'react-router-dom'
import Contact from '../Contact/Contact'
import Digital from '../Brandings'

const Body = () => {
    let location = useLocation();

    return (

        <BodyContainer>
            {location.pathname === "/" ? <HomePage /> : ""}
            {location.pathname === "/services" ? <Products /> : ""}
            {location.pathname === "/brands" ?<Digital/>:""}
            {location.pathname === "/contact" ? <Contact /> : ""}
        </BodyContainer>


    )
}

export default Body
