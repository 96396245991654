import React from 'react'
import { FaFacebookSquare, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa'
import { FooterContainer, FooterWrapper, FooterTop, FooterSocial, SocialIcons, FooterBottom, NavLinks } from './FooterElements'

const Footer = () => {

    const isActive = {
        color: "#00d2d1"
    };
    let date = new Date().getFullYear();

    return (
        <FooterContainer>
            <FooterWrapper>

                <FooterTop>
                    <NavLinks exact activeStyle={isActive} to="/">Home</NavLinks> | <NavLinks activeStyle={isActive} to="/services">Services</NavLinks> | <NavLinks activeStyle={isActive} to="/contact">Contact</NavLinks>
                </FooterTop>

                <FooterSocial>
                    <SocialIcons to='/contact' aria-label='Instagram'>
                        <FaInstagram />
                    </SocialIcons>
                    <SocialIcons to='/contact' aria-label='Twitter'>
                        <FaTwitter />
                    </SocialIcons>
                    <SocialIcons to='/contact' aria-label='Linkedin'>
                        <FaLinkedinIn />
                    </SocialIcons>
                    <SocialIcons to='/contact' aria-label='Facebook'>
                        <FaFacebookSquare />
                    </SocialIcons>

                </FooterSocial>

                <FooterBottom>
                    <a href="mailto:info@africomltd.com?subject=Service Inquiry&body=Hi! I am interested in knowing more about your services. Please call me back" rel="noreferrer" target="_blank" title="Send us mail">info@africomltd.com</a> | +254716000005 <br />
                    © {date} Africom Media. All right reserved

                </FooterBottom>

            </FooterWrapper>
        </FooterContainer>
    )
}

export default Footer
