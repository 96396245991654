import React from 'react'
import { Body, ButtonSection, ContactDesc, ContactDetails, ContactIcons, Container, Form, FormInput, FormTextArea, Heading, ImgSection, LeftSide, RightSide, SocialIconsSection, Top, SocialIcons, Name, Company, Email, Mobile, UpperForm } from './ContactElements'
import { FiPhoneCall, FiMail, FiMapPin } from "react-icons/fi";
import Button from '../Button';
import ImgBg from '../../images/body.jpeg';

import { FaFacebookSquare, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa'

const Contact = () => {
	const handleContact = () => {
		alert("Thanks for contacting us. We get back to you shortly!");
		//implement where the data will be stored

	}


	return (
		<Container style={{ backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.4)15%, rgba(0,0,0,0.1)), url(${ImgBg})` }}>
			<Top>
				<Heading style={{ color: "#00D3D3" }}>
					Don't be a stranger
				</Heading>
				<i>
					{" "}
					<Heading> say hello</Heading>
				</i>
			</Top>
			<Body>
				<LeftSide>
					<ImgSection />

					<ContactDetails>
						<ContactIcons>
							<FiMail />
						</ContactIcons>
						<ContactDesc>
							<a
								href="mailto:info@africomltd.com?subject=Service Inquiry&body=Hi! I am interested in knowing more about your services. Please call me back"
								rel="noreferrer"
								target="_blank"
								title="Send us a mail"
							>
								info@africomltd.com
							</a>
						</ContactDesc>
					</ContactDetails>

					<ContactDetails>
						<ContactIcons>
							<FiMapPin />
						</ContactIcons>

						<ContactDesc>
							<a
								href="https://maps.app.goo.gl/xTC28KWbawYskkQKA"
								rel="noreferrer"
								target="_blank"
								title="Open on google maps"
							>
								{" "}
								Bishop magua center 4th floor,Ngong road
							</a>
						</ContactDesc>
					</ContactDetails>

					<ContactDetails>
						<ContactIcons>
							<FiPhoneCall />
						</ContactIcons>
						<ContactDesc>+254 716 000 005</ContactDesc>
					</ContactDetails>

					<ContactDetails>
						<SocialIconsSection>
							<SocialIcons to="/contact" aria-label="Instagram">
								<FaInstagram />
							</SocialIcons>
							<SocialIcons to="/contact" aria-label="Twitter">
								<FaTwitter />
							</SocialIcons>
							<SocialIcons to="/contact" aria-label="Linkedin">
								<FaLinkedinIn />
							</SocialIcons>
							<SocialIcons to="/contact" aria-label="Facebook">
								<FaFacebookSquare />
							</SocialIcons>
						</SocialIconsSection>
					</ContactDetails>
				</LeftSide>

				<RightSide>
					<Form onSubmit={(e) => handleContact(e)}>
						<UpperForm>
							<Name>
								<FormInput
									type="text"
									placeholder="Full Name"
									required
								/>
							</Name>

							<Company>
								<FormInput
									type="text"
									placeholder="Company"
									required
								/>
							</Company>

							<Email>

								<FormInput
									type="email"
									placeholder="Email (xyz@abc.com)"
									required
								/>
							</Email>

							<Mobile>
								<FormInput
									type="tel"
									id="phone"
									name="phone"
									minLength="9"
									pattern="[0-9]*"
									placeholder="+254700000000  (9+ digit)"
									required
								/>
							</Mobile>
						</UpperForm>

						<FormInput
							style={{ width: "95%" }}
							type="text"
							placeholder="Subject"
							required
						/>

						<FormTextArea
							cols="30"
							rows="5"
							style={{ width: "95%" }}
							placeholder="Message"
							required
						/>

						<ButtonSection>
							<Button title="Submit" />
						</ButtonSection>
					</Form>
				</RightSide>
			</Body>
		</Container>
	);
}

export default Contact
