import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../Button'
import { Container, LeftSide, Paragraph, RightSide, Title, Wrapper, Content } from './OurCoreAboutElements'

const OurCore = () => {
    return (
		<Container>
			<Wrapper>
				<LeftSide>Our Core</LeftSide>

				<RightSide>
					<Content>
						<Title>Our Mission</Title>
						<Paragraph>
							Using the right technology to solve ordinary but
							extraordinarily complex challenges that people face.
						</Paragraph>
					</Content>

					<Content>
						<Title>Our Promise</Title>
						<Paragraph>
							We promise to be your innovative brain with
							outstanding customer service and excellent value for
							money.
						</Paragraph>
					</Content>

					<Link to="/services">
						<Button title="Our Services" />
					</Link>
				</RightSide>
			</Wrapper>
		</Container>
	);
}

export default OurCore
