import ussd from "../../images/ussdfinal.png";
import smsservice from "../../images/smsfinal.png";
import mpesa from "../../images/mpesafinal.png";
import vas from "../../images/vasfinal.png";
import rd from "../../images/rd.png";

export const homeObjone = {
  title: "USSD",
  description:
    `Create that simple way to interact with your customers and consumers with the help of our technical team in building simple interractive ussd programs. 
    USSD is a good solution to reach your any kind of a customer base since all mobile phones have USSD capabilities.`,
  ImgStart: true,
  img: ussd,
};
export const homeObjtwo = {
  title: "SMS",
  description:
    "SMS is that traditional way to engage with your customers or audience. It still works and it will still work for your business because we live in a society that is so close to mobile and SMS still remains part of the mobile's core. Utilize our API to deliver your SMS content and engagements. ",
  ImgStart: false,
  img: smsservice,
};
export const homeObjthree = {
  title: "Payment Gateway",
  description:
    "We offer customized payment solutions for all businesses to accept online, mobile or in-store payments. Join our global partners in using our payment gateway that allows your billing model to leverage M-Pesa payment for your Kenyan customers or audience. ",
  ImgStart: true,
  img: mpesa,
};
export const homeObjfour = {
  title: "VAS & CRBT",
  description:
    "Text, audio and video content or games can be monetized. Our billing platform enables you to bill and deliver your content in a seamless manner. we also provide CRBT management service to help you monetize your audio content.   ",
  ImgStart: false,
  img: vas,
};
export const homeObjfive = {
  title: "R&D",
  description:
    "Africom Media Limited intends to innovate solutions  that target specific niche problems in the various sectors of the economy, including but not limited to content, entertainment, fintech and consumer goods .The solutions target problems that people face daily.",
  ImgStart: true,
  img: rd,
};
