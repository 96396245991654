import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ImgBg from '../../images/topFinalreduced.jpg';




export const TopContainer = styled.div`
  background: linear-gradient(to right, rgba(0,0,0,0.1) 5%, rgba(0,0,0,0.2)), url(${ImgBg});
  /* background: transparent; */
  /* height: calc(100vh - 80px); */
  height: 50vh;
  background-position: center;
  background-size: cover;
`;

export const TopContent = styled.div`
  height: 100%;
  max-height: 100%;
`;

export const TopItems = styled.div`
  
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  
  text-align: right;
  padding-right: 15%;
  height: 100%;
  width: 100% ;
  color: #fff;
  line-height: 1;
  margin-right: auto;

  @media screen and (max-width:960px){
    padding: 0;
    
  }

`;

export const TopH2 = styled.h1`

  font-size: 80px;
  font-family: 'Anton', sans-serif;
  letter-spacing: 3px;
  margin-bottom: 20px;

  @media screen and (max-width : 960px){
    font-size: 36px;
    text-align: center
  }

`;

export const TopP = styled.p`
  font-size: 30px;
  letter-spacing: 12px;

  @media screen and (max-width : 960px){
    font-size: 16px;
  }

`;

export const ButtonSection = styled(Link)`
  text-align: right;
  /* padding-right: 25%; */

  @media screen and (max-width: 960px){
    text-align: center;
  }
`


