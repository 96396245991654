import styled from "styled-components";
import contactImg from '../../images/contactsFinalreduced.jpg'
import { Link } from "react-router-dom"

export const Container = styled.div`

    min-height: calc(100vh - 80px);
    background: linear-gradient(to bottom, rgba(0,39,61,0.7), rgba(0,39,61,0.6));
/* background: #083E65; */
    padding: 1.5% 15%;
    padding-bottom: 2.5%;

    @media screen and (max-width:960px){
        padding: 1.5% 2.5%;
        padding-bottom: 2.5%;
    }

`
export const Top = styled.div`
    color: #fff;
    text-align: center;
`

export const Heading = styled.h3`
    font-size: 50px;
    font-family: 'Anton', sans-serif;

    @media screen and (max-width:960px){
        font-size: 30px;
    }
`

export const Body = styled.div`
    height: 70%;
    background: #fff;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 5%;

    @media screen and (max-width: 960px){
        grid-template-columns: 1fr;
    }


`
export const LeftSide = styled.div`
    border-right: 1px solid #A6A6A6;
    padding: 2.5% 2.5%;

    @media screen and (max-width: 960px){
        border: none;
    }
    
   
    
`

export const ImgSection = styled.div`
    background-image: url(${contactImg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    height: 240px;
    width: 240px;
    border-radius: 30%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

`

export const ContactDetails = styled.div`
   
    color: #083E65;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-bottom: 20px;
    width: 240px;
    margin-left: auto;
    margin-right: auto;
    
    

`

export const ContactIcons = styled.div`
    font-size: 25px;
    margin-right: 20px;

    
`
export const ContactDesc = styled.div`
    font-size: 18px;

    a{
        color: #083E65;
        text-decoration: none;
    }
    a:hover{
        transition: .3s ease-in-out;
        font-size: 19px;
        color: #00D3E3;
    }
    
    @media screen and (max-width:960px){
        font-size: 16px;
    }
`


export const RightSide = styled.div`
    padding: 2.5% 5%;


    
`

export const Form = styled.form`
    margin-left: auto;
    margin-right: auto;
    


`

export const UpperForm = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const Name = styled.div`

width: 50%;

@media screen and (max-width: 960px)
{
    width: 100%;
}

`

export const Company = styled.div`

width: 50%;
padding-left: 5%;

@media screen and (max-width: 960px)
{
    width: 100%;
    padding-left: 0%;
}


`

export const Email = styled.div`

width: 50%;

@media screen and (max-width: 960px)
{
    width: 100%;
}
`
export const Mobile = styled.div`

width: 50%;
padding-left: 5%;

@media screen and (max-width: 960px)
{
    width: 100%;
    padding-left: 0%;
}
`



export const FormInput = styled.input`
     padding: 20px 10px;
     width: 90%;
     border-radius: 10px;
     background-color: #ECEBEB;
     border: none;
     margin-bottom: 30px;

     font-size: 16px;

     @media screen and (max-width: 960px){
        width: 95%;
        padding: 10px 10px;
    }
    
`

export const FormTextArea = styled.textarea`
     padding: 20px 10px;
     border-radius: 10px;
     background-color: #ECEBEB;
     border: none;
     margin-bottom: 30px;
     font-size: 16px;

`

export const ButtonSection = styled.div`
 
    text-align: right;
    margin-right: 5%;
    
`


export const SocialIconsSection = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;



`

export const SocialIcons = styled(Link)`
    font-size: 24px;
    cursor: pointer;
    color: #083E65;

    &:hover{
        color: #00D3E3;
        transform: scale(1.2);
        
        
    }

    @media screen and (max-width: 960px){
        font-size: 24px;
    }
`

