import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../Button'
import { ButtonSection, Container, Content, Paragraph, Wrapper } from './OurMissionBannerElements'

const OurMissionBanner = () => {
    return (
        <Container>
            <Wrapper>
                <Content>
                    <Paragraph>
                        Unlocking the next <br /> level in telecom
                    </Paragraph>
                    <ButtonSection>
                        <Link to="/services">
                            <Button title="Our Service">Our Service</Button>
                        </Link>
                    </ButtonSection>
                </Content>
            </Wrapper>

        </Container >
    )
}

export default OurMissionBanner
