import styled from "styled-components";
import ImgBg from '../../images/whitedots.jpg'

export const Container = styled.div`

       min-height: calc(50vh - 80px);
       background-image: linear-gradient(to right, rgba(55,68,106,0), rgba(55,68,106,0)), url(${ImgBg});
    
       background-repeat: no-repeat;
       background-size: cover;
       color: #073c64;

       display: flex;
       justify-content: center;
       


       @media screen and (max-width : 960px){
        height: auto;
        background: #fff;
        padding: 5% 0;
        

    }
`

export const Wrapper = styled.div`
    
    min-height: 90%;  
    margin: 0 15%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 1.5%;
    

    @media screen and (max-width : 960px){
        grid-template-columns: 1fr;
        min-height: auto;
        margin: 0 2.5%;
    }


`

export const LeftSide = styled.h2`

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: 700;

    @media screen and (max-width:960px){
        font-size: 30px;
    }


`

export const RightSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     line-height: 1.6;
  

`

export const Title = styled.h3`
    font-size: 26px;

    @media screen and (max-width:960px){
        font-size: 20px;
    }
    
`

export const Paragraph = styled.p`
    font-size: 18px;
     line-height: 1.6;
   

    @media screen and (max-width:960px){
        font-size: 16px;
         line-height: 1.6;
    }

`

export const Content = styled.div`
    margin: 20px;
    text-align: center;
`

export const Button = styled.button`
    background: #00d2d1;
    color: #073c64;
    border: none;
    border-radius: 20px;
    font-size: 20px;
    padding: 10px 25px;
    padding-top: 14px;
    cursor: pointer;
    /* width: 12%; */

    &:hover{
        color: #fff;
    }

    @media screen and (max-width: 960px){
        font-size: 18px;
        padding: 2.5px 12px;
        margin-left: auto;
        margin-right: auto;
    
    }
`

