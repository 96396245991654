import styled from "styled-components";


export const Container = styled.div`
    background: #fff;
    color: #073c64;
    min-height: 27vh;

    display: flex;
    justify-content: center;
    align-items: center;



`

export const Wrapper = styled.div`
    
    padding: 1% 0;
    width: 100%;
    height: 100%;
    margin: 0 15%;
    border-bottom: 1px solid #C6C1C1;
    display: grid;
    grid-template-columns: 1fr 1fr;

    grid-template-areas:
    ${({ ImgStart }) =>
        ImgStart ? `'imageSide contentSide'` : `'contentSide imageSide'`};
    
    
    @media screen and (max-width: 960px){
      margin: 0 2.5%;  
      padding: 5% 0;    

      grid-template-columns: 1fr;
      grid-template-areas: 
      "imageSide"
      "contentSide";
    }
`

export const ImageSide = styled.div`
    grid-area: imageSide;

    height: 100%;
    padding: 1.5% 2.5%;
    

    display: flex;
    justify-content: center;
    align-items: center;


`

export const ImageSection = styled.image`

   img{
    height: 160px;
    width: 160px;
    
   }
   
   @media screen and (max-width: 960px){
    img{
    
    height: 130px;
    width: 130px;
   } 
   }

`


export const ContentSide = styled.div`

      grid-area: contentSide;
      height: 100%;
      padding: 1.5% 2.5%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

   


`

export const Title = styled.h3`
font-size: 26px;

@media screen and (max-width:960px){
    font-size: 20px;
}


`

export const Paragraph = styled.p`
  font-size: 18px;
   margin: 20px 0;
   padding: 0 2.5%;
   text-align: center;
    line-height: 1.6;

   @media screen and (max-width:960px){
        font-size: 16px;
        padding: 0 ;
        
    }

`