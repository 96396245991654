import React from 'react'
import AboutUs from './AboutUs';
// import About from '../About';
import Bottom from './Bottom';
import OurCore from './OurCore';
import OurMissionBanner from './OurMissionBanner';
import Top from './Top';


const HomePage = () => {
  return (
    <>
      <AboutUs />
      <Top />
      <OurCore />
      <OurMissionBanner />
      <Bottom />
    </>
  )
}

export default HomePage
