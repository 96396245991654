import React from 'react'
import { Container1 } from './BrandingsElements'
function Gadgets(data) {
    return (
        <Container1>
            <div><ul>
                <li>
                    lanyards
                </li>
                <li>
                    Magnetic foil
                </li>
                <li>
                    Pen
                </li>

            </ul></div>
            <div><ul>
                <li>
                    Flash drive
                </li>
                <li>
                    Product information
                </li>
                <li>
                    Gondolas
                </li>

            </ul>
            </div>
            <div><ul>
                <li>
                    Light Boxes
                </li>
                <li>
                    Hanging Signs
                </li>

                <li>
                    Reflective bands
                </li>
            </ul></div>
        </Container1>
    )
}

export default Gadgets