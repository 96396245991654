
import { useLocation } from 'react-router';
import {
  HeroContainer,
  HeroContent,
  HeroItems,
  HeroH1,
  HeroP, HeroInner, ScrollDownSection, HerosCREATIVE, HeroVALUABLE, Unique, HeroIrresistible, HeroMODERN, HerosINNOVATIVE, HerosWeCreate
} from './HeroElements';

import { FiArrowDownCircle } from "react-icons/fi";

import productBg from '../../images/heroProductsreduced.jpg';
import homeBg from '../../images/heroFinal2reduced.jpg';
import contactBg from '../../images/contactsFinalreduced.jpg';
import branding from '../../images/Mockup4.jpg';

const Hero = () => {

  var bgImage = ''
  var service =''
  var scrollTo = ''

  const location = useLocation();

  if (location.pathname === '/') {
    bgImage = homeBg;
    service = "TELECOM";
    scrollTo = "#about";
  }
  else if (location.pathname === '/services') {
    bgImage = productBg;
    service = "TELECOM ";
    scrollTo = "#services";
  }
  else if (location.pathname === '/contact') {
    bgImage = contactBg;
  }
  else if (location.pathname === '/brands') {
    service = "BRANDING";
    bgImage = branding;
    scrollTo = "#brands";
  }

  return (
    <HeroContainer style={{ backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.9)15%, rgba(0,0,0,0)), url(${bgImage})` }}>
      
      <HeroContent>
        {location.pathname === '/' ?
          <HeroItems>
            <HeroInner>
              <HerosWeCreate style={{ color: "#00d2d1", fontSize: "50px", display: "flex", alignItems: "end" }}>WE CREATE </HerosWeCreate>
              <HeroH1>INNOVATIVELY,</HeroH1>
            </HeroInner>
            <HeroInner>
              <HeroH1 style={{ color:"#cae9ff"}} >WE BRAND </HeroH1>
              <HeroH1 style={{ color: "#00d2d1" ,fontSize:"60px" ,display:"flex",alignItems:"end"}}>CREATIVELY</HeroH1>
            </HeroInner>
          </HeroItems> :("")}

        {location.pathname === '/services' ?
          <HeroItems>
            <HeroInner>
              <HerosCREATIVE style={{ color: "#cae9ff" }}>CREATIVE </HerosCREATIVE>
            </HeroInner>
            <HeroInner >
              <HerosINNOVATIVE style={{ paddingLeft: "0%" }} >INNOVATIVE</HerosINNOVATIVE>
            </HeroInner>
            <HeroInner>
              <HeroVALUABLE style={{ color: "#00d2d1" }}>VALUABLE </HeroVALUABLE>
            </HeroInner>
          </HeroItems> : ("")}

        {location.pathname === '/brands' ?
          <HeroItems>
            <HeroInner>
              <Unique style={{ color: "#cae9ff"}}>UNIQUE </Unique>
            </HeroInner>
            <HeroInner style={{}}>
              <HeroIrresistible >IRRESISTIBLE</HeroIrresistible>
            </HeroInner>
            <HeroInner>
              <HeroMODERN style={{ color: "#00d2d1" }}>MODERN </HeroMODERN>
            </HeroInner>
          </HeroItems> : ("")}


       
        
      </HeroContent>
      <ScrollDownSection>
        <a href={scrollTo}><FiArrowDownCircle title="Jump to about" /></a>
      </ScrollDownSection>
    </HeroContainer>
  );
};

export default Hero;
