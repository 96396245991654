import styled from "styled-components";
import ImgBg from '../../images/aboutBanner.jpg'

export const Container = styled.div`
    min-height: 50vh;
    background: linear-gradient(to right, rgba(0,0,0,0.3), rgba(0,0,0,0)), url(${ImgBg});
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 15%;


    display: flex;
    align-items: center;

    @media screen and (max-width : 960px){

        padding: 0 2.5%;
       
    }

`

export const Wrapper = styled.div`
    
    width: 60%;
    height: 90%;
    padding-top: 1.5%;
    padding-bottom: 1.5%;

    @media screen and (max-width : 960px){
        width: 100%;
    }

`
export const Content = styled.div`
  
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: right; */
    /* align-items: center; */
    /* margin: 0 5%; */

    

    @media screen and (max-width : 960px){
        margin: 0 2.5%;

    }
`

export const Paragraph = styled.h3`

    color: #fff;
    margin-bottom: 20px;
    font-size: 80px;
    font-family: 'Anton', sans-serif;
    letter-spacing: 3px;
    text-transform: uppercase;
    text-align: left;
    line-height: 1em;
    @media screen and (max-width : 960px){
      
        font-size: 36px;
        text-align: center
    }
`
export const ButtonSection = styled.div`
    width: 100%;

    @media screen and (max-width : 960px){
      
      /* font-size: 36px; */
      text-align: center
  }

`

