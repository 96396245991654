import {
  BottomContainer,
  BottomContent,
  BottomItems,
  BottomH2,
  Forward
} from './BottomElements';
import ImgBg from '../../images/body.jpeg';
const Bottom = () => {
  return (
    <BottomContainer style={{ backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.4)15%, rgba(0,0,0,0.1)), url(${ImgBg})` }}>
      <BottomContent>
        <BottomItems>
          <BottomH2>FINTECH SOLUTIONS<br />
            DRIVING ECONOMICS <br />
            <Forward>FORWARD </Forward>
            <i style={{ fontFamily: 'Anton' }}>FORWARD</i></BottomH2>
        </BottomItems>
      </BottomContent>
    </BottomContainer>
  )
}

export default Bottom
