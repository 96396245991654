import React from 'react'
import { Container1 } from './BrandingsElements'
function OtherBranding(data) {
    return (
        <Container1>
            <div><ul>
                <li>
                    Letter head
                </li>
                <li>
                    Folders
                </li>
                <li>
                    Desk Pad Planners
                </li>
                <li>
                    packaging
                </li>
            </ul></div>
            <div><ul>
                <li>
                    Packages
                </li>
                <li>
                    Stickers
                </li>
                <li>
                    Catalogues
                </li>
                <li>
                    Christmas and other cards
                </li>
            </ul>
            </div>
            <div><ul>
                <li>
                    Calendars
                </li>
                <li>
                    Labels
                </li>
                <li>
                    Envelopes
                </li>
            </ul></div>
        </Container1>
    )
}

export default OtherBranding