import { BrowserRouter as Router, Route } from "react-router-dom";
import Body from "./Components/Body";
import Footer from "./Components/Footer";
import Hero from "./Components/Hero";
import NewNav from "./Components/NewNav";
import ScrollToTop from "./Components/ScrollToTop";
import GlobalStyle from "./globalStyles";



function App() {

  return (
    <>
      <Router>
        <ScrollToTop />
        <GlobalStyle />
        <NewNav />
        <Route exact path='/' component={Hero} />
        <Route exact path='/services' component={Hero} />
        <Route exact path='/brands' component={Hero} />
        <Body />
        <Route exact path='/' component={Footer} />
        <Route exact path='/services' component={Footer} />
        <Route exact path='/brands' component={Footer} />
      </Router>

    </>
  );
}
export default App;
