import React from 'react'
import { homeObjone, homeObjtwo, homeObjthree, homeObjfour, homeObjfive } from './data'
import Products from './products'


const index = () => {



  return (
    <>
      <Products data={homeObjone} />
      <Products data={homeObjtwo} />
      <Products data={homeObjthree} />
      <Products data={homeObjfour} />
      <Products data={homeObjfive} />



    </>
  )
}

export default index
