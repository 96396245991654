import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../Button'
import { Container, LeftSide, Paragraph, RightSide, Title, Wrapper, Content } from './OurCoreAboutElements'

const AboutUs = () => {
	return (
		<Container id="about">
			<Wrapper>
				<LeftSide>About Us</LeftSide>

				<RightSide>
					<Content>
						<Title>Who we are</Title>

						<Paragraph>
							Africom Media Limited is a tech-driven organization
							with an aim of using the right technology to explore
							and innovate new solutions in the dynamic world of
							mobile and web content. We partner with other
							players in the mobile content industry to aggregate, bill and deliver
							content to the consumer the best way.
							<br />
							<br />
							With a rapidly growing smartphone penetration in the
							region, we look forward to enhancing how content is
							aggregated, billed and delivered to the consumer.
							<br />
							<br />
							At Africom Media Limited we believe in people and technology, that is why we innovate solutions that target daily ordinary challenges that have an extraordinary scale of complexity.
						</Paragraph>
					</Content>

					<Link to="/services">
						<Button title="Our Services" />
					</Link>
				</RightSide>
			</Wrapper>
		</Container>
	);
}

export default AboutUs
