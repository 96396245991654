import styled from 'styled-components';
import ImgBg from '../../images/heroFinal2reduced.jpg';

export const HeroContainer = styled.div`
  background: linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0.2)),url(${ImgBg});
  height: calc(100vh - 60px);
  background-position: center;
  background-size: cover;
  
`;

export const HeroContent = styled.div`
  /* height: calc(100vh - 60px); */
  height: 100%;
  max-height: 100%;
`;

export const HeroItems = styled.div`
  
  
  display: flex;
  flex-direction: column;
  justify-content: center;
 

  /* height: calc(100vh - 80px); */
  height: 100%;
  width: 100% ;
  color: #fff;
  line-height: 1;
  margin-right: auto;

`;

export const HeroInner = styled.div`
padding-top: 1%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: left;
  margin-left: 14%;
   


  @media screen and (max-width: 960px){
    padding-top: 5%;
    width: 100%;
    margin-left: 0;
    flex-direction: column ;
    align-items: center;
  }
`

export const ScrollDownSection = styled.div`
  font-size: 56px;
  text-align: center;
  position: relative;
  top: -100px;
  cursor: pointer;
  width: 30%;
  margin-left: auto;
  margin-right: auto;

  a{
    text-decoration: none;
    color: #fff;
  }

  a:hover{
    color: #00d2d1;
  }

  &:hover{
    transition: .4s ease-in-out;
    color: #00d2d1;
    transform: scale(1.3);
    
  }

  @media screen and (max-width:960px){
    font-size: 36px;
  }
  

`

export const HeroH1 = styled.h1`

  font-size: 75px;
  font-family: 'Anton', sans-serif;
  letter-spacing: 5px;

  @media screen and (max-width : 960px){
    font-size: 45px;
  }

`;



export const HerosH1 = styled.h1`

  font-size: 75px;
  font-family: 'Anton', sans-serif;
  letter-spacing: 3px;
  

  @media screen and (max-width : 960px){
    font-size: 45px;
  }

`;


export const HerosWeCreate = styled.h1`

  font-size: 65px;
  font-family: 'Anton', sans-serif;
  letter-spacing: 5px;
  

  @media screen and (max-width : 960px){
  
   display: flex;
   align-items: end;
     font-size: 5px;

  }
   @media screen and (max-width : 320px){
    font-size: 30px;
     transform: rotate(270deg); 
     margin-left: 0%;
     margin-bottom: 12%;
  }

`;

export const HerosCREATIVE = styled.h1`

  font-size: 65px;
  font-family: 'Anton', sans-serif;
  letter-spacing: 5px;
  transform: rotate(0deg); 
  margin-left: 13%;
  margin-bottom: 0%;
  padding-top: 9.9%;
  position:absolute ;

  @media screen and (max-width : 960px){
    font-size: 25px;
     transform: rotate(0deg); 
     margin-left: 10%;
     margin-bottom: 12%;
      padding-top: 35%;
  }
   @media screen and (max-width : 320px){
    font-size: 30px;
     transform: rotate(270deg); 
     margin-left: 0%;
     margin-bottom: 12%;
  }
   @media screen and (max-width : 375px){
    font-size: 30px;
     transform: rotate(0deg); 
     margin-left:20%;
     margin-bottom: 12%;
  }


`;

export const HerosINNOVATIVE = styled.h1`

  font-size: 80px;
  font-family: 'Anton', sans-serif;
  letter-spacing: 5px;
  transform: rotate(270deg); 
  margin-left: 0%;
  margin-bottom: 0%;

  @media screen and (max-width : 960px){
    font-size: 45px;
     transform: rotate(270deg); 
     margin-left: -35%;
     margin-bottom: 12%;
  }
   @media screen and (max-width : 320px){
    font-size: 30px;
     transform: rotate(270deg); 
     margin-left: 0%;
     margin-bottom: 12%;
  }

`;

export const HeroVALUABLE = styled.h1`

  font-size: 55px;
  font-family: 'Anton', sans-serif;
 letter-spacing: 5px;
  transform: rotate(0deg); 
  margin-left: 5%;
  margin-bottom: 0%;
  margin-top: 9% ;

  @media screen and (max-width : 960px){
    font-size: 45px;
     transform: rotate(0deg); 
     margin-left: -35%;
     margin-bottom: 0%;
     margin-top: 10% ;
  }
   @media screen and (max-width : 320px){
    font-size: 30px;
     transform: rotate(270deg); 
     margin-left: 45%;
     margin-bottom: 0%;
     margin-top: 10% ;
  }

`;

export const Unique = styled.h1`

  font-size: 75px;
  font-family: 'Anton', sans-serif;
  letter-spacing: 5px;
  transform: rotate(270deg); 
  margin-left: -4.6%;
  margin-bottom:4%;
  margin-top: 0% ;

  @media screen and (max-width : 960px){
    font-size: 45px;
     transform: rotate(270deg); 
     margin-left: -35%;
     margin-bottom: 4%;
     margin-top: 0% ;
  }
   @media screen and (max-width : 320px){
    font-size: 30px;
     transform: rotate(270deg); 
     margin-left: -35%;
     margin-bottom: 0%;
     margin-top: 10% ;
  }

`;
export const HeroIrresistible = styled.h1`

  font-size: 50px;
  font-family: 'Anton', sans-serif;
  letter-spacing: 5px;
  transform: rotate(0deg); 
  margin-left: 0%;
  margin-bottom:0%;
  margin-top: 0% ;

  @media screen and (max-width : 960px){
    font-size: 25px;
     transform: rotate(0deg); 
     margin-left: 0%;
     margin-bottom: 0%;
     margin-top: 7% ;
  }
   @media screen and (max-width : 320px){
    font-size: 30px;
     transform: rotate(0deg); 
     margin-left: 0%;
     margin-bottom: 0%;
     margin-top: 10% ;
  }

`;

export const HeroMODERN = styled.h1`

  font-size: 65px;
  font-family: 'Anton', sans-serif;
  letter-spacing: 5px;
  transform: rotate(270deg); 
  margin-left:8.7%;
  margin-bottom:0%;
  margin-top: 4% ;

  @media screen and (max-width : 960px){
    font-size: 35px;
     transform: rotate(270deg); 
     margin-left: 35%;
     margin-bottom: 0%;
     margin-top: 8% ;
  }
   @media screen and (max-width : 320px){
    font-size: 30px;
     transform: rotate(270deg); 
     margin-left: 45%;
     margin-bottom: 0%;
     margin-top: 8% ;
  }

`;


export const HeroP = styled.p`
  font-size: 30px;
  letter-spacing: 12px;
  font-weight: bold;
  
  

  @media screen and (max-width : 960px){
    font-size: 16px;
  }

`;


