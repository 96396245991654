import styled from 'styled-components';
import ImgBg from '../../images/body.jpeg';


export const BodyContainer = styled.div`
  /* background: url(${ImgBg}); */
  /* padding-top: 40px;
  padding-bottom: 40px; */

  @media screen and (max-width: 960px){
    /* padding: 20px 0; */
  }
  
`

