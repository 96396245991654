import React from 'react'
import Button from '../Button';

import {
    TopContainer,
    TopContent,
    TopItems,
    TopH2,
    ButtonSection
} from './TopElements';

const Top = () => {
    return (
        <TopContainer>
            <TopContent>
                <TopItems>
                    <TopH2>EASY ACCESS TO<br />
                        MOBILE SOLUTIONS<br />
                    </TopH2>
                    <ButtonSection to="/services">
                        <Button title="Our Services" />
                    </ButtonSection>

                </TopItems>
            </TopContent>
        </TopContainer>
    )
}

export default Top
