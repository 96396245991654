import React from 'react'
import { BusinessCards, Posters, Flyers, VehicleBranding, Banners, WallBranding, Textiles, Lighters, Mugs} from './data'
import Digital from './ brands'
import OtherBranding from './OtherBranding'
import Title from './Title'
import GetStartedButton from './GetStartedButton'
import PageDivider from './PageDivider'
import LargerFormat from './LargerFormat'
import Gadgets from './Gadgets'
import PageDivider1 from './PageDivider1'



const index = () => {



  return (
    <>
      <Title data={"Printing"} />
      <Digital data={BusinessCards} />
      <Digital data={Posters} />
      <Digital data={Flyers} />
      <Title data={"Other printings"} />
      <OtherBranding />
      <GetStartedButton />
      <PageDivider1 data={"Large Format"} />
      <Digital data={Banners} />
      <Digital data={VehicleBranding} />
      <Digital data={WallBranding} />
      <Title data={"Other Large Format"} />
      <LargerFormat/>
      <GetStartedButton />
      <PageDivider data={"Gadgets"} />
      <Digital data={Textiles} />
      <Digital data={Lighters} />
      <Digital data={Mugs} />
      <Title data={"Gadgets"} />
      <Gadgets/>
      <GetStartedButton />
    </>
  )
}

export default index
