import { useState } from 'react'
import { FaTimes, FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import logo from './../../images/africomlogo.png'


import {
    Nav,
    NavbarContainer,
    NavLogo,
    MobileIcon,
    NavMenu, NavItem, NavLinks
} from './NavElements'


const NewNav = () => {

    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);

    const isActive = {
        color: "#00d2d1"
    };

    return (
        <>
            <IconContext.Provider value={{ color: '#000' }}>
                <header>
                    <Nav>
                        <NavbarContainer>
                            <NavLogo exact to="/">
                                {/* <NavIcon />
                            Africom Media */}
                                <img src={logo} alt="logo" />
                            </NavLogo>

                            <MobileIcon onClick={handleClick} >
                                {click ? <FaTimes /> : <FaBars />}
                            </MobileIcon>

                            <nav>
                                <NavMenu onClick={handleClick} click={click}>
                                    <NavItem>
                                        <NavLinks exact activeStyle={isActive} to="/" >
                                            HOME
                                        </NavLinks>
                                    </NavItem>

                                    <NavItem>
                                        <NavLinks activeStyle={isActive} to="/services">
                                            DIGITAL
                                        </NavLinks>
                                    </NavItem>

                                    <NavItem>
                                        <NavLinks activeStyle={isActive} to="/brands" >
                                            BRANDING
                                        </NavLinks>
                                    </NavItem>

                                    <NavItem>
                                        <NavLinks activeStyle={isActive} to={{ pathname: `https://africomintranet.com/jobs` }} target="_blank">
                                            JOBS
                                        </NavLinks>
                                    </NavItem>

                                    <NavItem>
                                        <NavLinks activeStyle={isActive} to="/contact">
                                            CONTACT
                                        </NavLinks>
                                    </NavItem>

                                </NavMenu>
                            </nav>
                        </NavbarContainer>
                    </Nav>
                </header>
            </IconContext.Provider>
        </>
    )
}

export default NewNav

